import React, { useState, useEffect } from "react";
import axios from "axios";
import commonData from "../../importanValue";

const TodayExams = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        type: "gettodaysexams",
        search: "",
        qid: "",
      };

      try {
        const response = await axios.post(`${commonData.api}/support`, body);
        const transformedData = await transformData(response.data[0]);
        console.log("🚀 ~ fetchData ~ transformedData:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const transformData = (input) => {
    const packageMap = new Map();

    input.forEach((item) => {
      const {
        group_name,
        quiz_name,
        examdescription,
        whatsapplink,
        gid,
        bucket,
      } = item;

      if (!packageMap.has(group_name)) {
        packageMap.set(group_name, {
          packageName: group_name,
          exams: [],
          whatsappGroup: whatsapplink,
          gid,
          bucket,
        });
      }

      packageMap.get(group_name).exams.push({
        title: quiz_name,
        syllabus: examdescription,
      });
    });

    return Array.from(packageMap.values());
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ padding: "20px", background: "#f0f0f0" }}>
      {/* <button
        onClick={handlePrint}
        style={{
          marginBottom: "20px",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px",
          // background: "#007bff",
          color: "#fff",
          border: "none",
          cursor: "pointer",
        }}
      >
        Print
      </button> */}
      <img
        src="https://bit.ly/3VWxb9V"
        alt="Main Header Banner"
        style={{ width: "100%", height: "auto" }}
      />
      <div
        style={{
          marginBottom: "20px",
          backgroundColor: "#fff",
          padding: "15px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ul>
          {data.map((packageData, index) => (
            <li key={index}>{packageData.packageName}</li>
          ))}
        </ul>
      </div>
      {data.map((packageData, index) => (
        <div
          key={index}
          className="each-package-info"
          style={{
            marginBottom: "20px",
            backgroundColor: "#fff",
            padding: "15px",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            pageBreakAfter: "always",
          }}
        >
          <h2 style={{ marginBottom: "10px", color: "#007bff" }}>
            {packageData.packageName}
          </h2>
          <div>
            {packageData.exams.map((exam, i) => (
              <div key={i} style={{ marginBottom: "10px",border:"1px solid gray",paddingLeft:5 }}>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: `${i + 1}. ${exam.title}`,
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: exam.syllabus }} />
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <a
              href={`${commonData.app}/payumoney/payment/checkout/${
                packageData.gid
              }${
                packageData.bucket !== "0"
                  ? "/aG9tZQ=="
                  : "/" + window.btoa(packageData.bucket)
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bit.ly/3xyjtlh"
                alt="WhatsApp link"
                style={{ width: "100%" }}
              />
              {/* View Package */}
            </a>
            <a
              href={packageData.whatsappGroup}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bit.ly/4caN1Et"
                alt="Exam link"
                style={{ width: "100%" }}
              />
              {/* Join WhatsApp Group */}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TodayExams;
