import { Component } from "react";
import OneSignal from "react-onesignal";
import axios from "axios";
import commonData from "../../importanValue";

const sendPush = async (data2) => {
  var headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Basic  ${commonData["auth_key"]}`,
  };
  const body = {
    headings: { en: data2.title },
    contents: { en: data2.message },
    app_id: commonData["app_id"],
    included_segments: ["Subscribed Users"],
    ...data2,
  };
  console.log(body);
  const data = await axios.post(
    `https://onesignal.com/api/v1/notifications`,
    body,
    { headers }
  );
  console.log(data);
  return data.data.recipients;
};

export default sendPush;
