import { Redirect, Route } from "react-router-dom";
import Cookie from "js-cookie";
import { useLocation } from "react-router-dom";
import OneSignal from "react-onesignal";
import { useEffect, useState } from "react";
import commonData from "../importanValue";
import axios from "axios";

const ProtectedRoute = (props) => {
  const token = Cookie.get("jwt_token");
  const location = useLocation();
  const uid = localStorage.getItem("num");
  const [showSelection, setShowSelection] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookie.get("jwt_token");

        const headers = {
          "Content-Type": "application/json",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        };
        const userDetails = await axios.post(
          `${commonData["api"]}/get-user-details/${uid}`,
          {
            headers,
          }
        );
        if (userDetails) {
          const subStr = userDetails.data.result[0].subjects_selected;
          setShowSelection(subStr === "");
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      }
    };
    if (
      token !== undefined &&
      !location.pathname.includes("select-the-exams")
    ) {
      fetchUserDetails();
    }
  }, [uid, location]);
  console.log("location", location.search);
  if (token === undefined) {
    // const next = localStorage.getItem("prev");
    // // alert("దయచేసి ముందుగా లాగిన్ చేయండి");
    if (
      String(location.search).includes("?refer=") &&
      localStorage.getItem("refer") === undefined
    ) {
      localStorage.setItem("refer", location.search.split("?refer=")[1]);
    }
    return <Redirect to={`/login/?next=${location.pathname}`} />;
  }
  if (showSelection) {
    return <Redirect to={`/select-the-exams?next=${location.pathname}`} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
