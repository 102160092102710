import React, { useState, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom"; // Import useHistory for redirection
import axios from "axios";
import "./SubjectSelection.css"; // Import CSS file for styling
import commonData from "../importanValue";
import Cookie from "js-cookie";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Button from "@mui/material/Button";

const SubjectSelection = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  console.log("🚀 ~ SubjectSelection ~ selectedSubjects:", selectedSubjects)
  const [subjectsString, setSubjects] = useState([]);
  const nextUrl = location.search.split("?next=")[1];
  console.log("🚀 ~ file: subjects.js:19 ~ SubjectSelection ~ nextUrl:", nextUrl)

  const uid = localStorage.getItem("num");
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookie.get("jwt_token");

        const headers = {
          "Content-Type": "application/json",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        };
        const userDetails = await axios.post(
          `${commonData["api"]}/get-user-details/${uid}`,
          {
            headers,
          }
        );
        if (userDetails) {
          const subStr = userDetails.data.result[0].subjects;
          const subjectsArray = subStr.split("$$");
          setSubjects(subjectsArray);
        }
 
      
         
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      }
    };

    fetchUserDetails();
  }, []);  

  const toggleSubject = (id) => {
    let ids=[]
    ids.push(id)
    setSelectedSubjects(ids);
    // setSelectedSubjects(
    //   selectedSubjects.filter((subjectId) => subjectId !== id)
    // );
    // if (selectedSubjects.includes(id)) {
      
    // } else {
    //   if (selectedSubjects.length >= 1) {
    //     NotificationManager.error("Select your 1 main goals only.");
    //     return
    //   }
     
    // }
  };

  const handleSubmit = async () => {
    try {
      // Perform API call here with selectedSubjects
      // Replace 'API_URL' with your actual API endpoint
      if (selectedSubjects.length === 0) {
        // Show error notification if no subjects are selected
        NotificationManager.error("Please select at least one subject");
        return; // Prevent further execution if no subjects are selected
      }

      const token = Cookie.get("jwt_token");
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
        "Access-Control-Allow-Origin": "*",
      };
      const uid = localStorage.getItem("num");
      const body = {
        type: "storeSubjects",
        search: selectedSubjects.join(","),
        qid: uid,
      };
      try {
        const data = await axios.post(`${commonData["api"]}/support`, body, {
          headers,
        });
        if (nextUrl !== undefined) {
          history.replace(nextUrl);
        }else{
          history.replace("/");
        }
        NotificationManager.success("Data updated successfully.");
      } catch (err) {}
    } catch (error) {
      console.error("Error submitting subjects:", error.message);
    }
  };

  return (
    <>
      <div className="subject-selection-container">
        <h3>
          మీరు ఏ Examsకి <br /> ప్రిపేర్ అవుతున్నారు
        </h3>
        <div className="subjects-grid">
          {subjectsString.map((subject) => {
            const [id, name] = subject.split("_");
            const isSelected = selectedSubjects.includes(id);
            return (
              <div
                key={id}
                className={`subject-item ${isSelected ? "selected" : ""}`}
                onClick={() => toggleSubject(id)}
              >
                {name}
              </div>
            );
          })}
        </div>
        {/* <button className="submit-button" type="button" onClick={handleSubmit}>
          Submit
        </button> */}
        <Button
          type="submit"
          variant="contained"
          className="btn activateacoountbtn submibtn"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      <div>
        <NotificationContainer />
      </div>
    </>
  );
};

export default SubjectSelection;
