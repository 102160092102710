import { createSlice } from "@reduxjs/toolkit";

const examsSlice = createSlice({
  name: "counter",
  initialState: {
    mobileVerification: false,
    number: "",
    username: "",
    quizId: "",
    answers: [],
    number: [],
    quizDetails: [],
  },
  reducers: {
    verifyMobile: (state, action) => ({
      ...state,
      mobileVerification: action.payload,
    }),
    saveNum: (state, action) => ({ ...state, number: action.payload }),
    saveQuizId: (state, action) => ({ ...state, quizId: action.payload }),
    saveAnswers: (state, action) => ({ ...state, answers: action.payload }),
    saveQuizDetails: (state, action) => ({
      ...state,
      quizDetails: action.payload,
    }),
  },
});

export const {
  verifyMobile,
  saveQuizId,
  saveAnswers,
  saveQuizDetails,
  saveNum,
} = examsSlice.actions;

export default examsSlice.reducer;
