import { Component } from "react";
import Header from "../Header";
import Loader from "../Loader";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Cookie from "js-cookie";
import Button from "@mui/material/Button";
import AdminMenu from "./AdminMenu";
import { Link } from "react-router-dom";
import commonData from "../../importanValue";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import invalid from "../invalid.png";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Checkbox from "@mui/material/Checkbox";
// import { Editor } from "@tinymce/tinymce-react";
import sendpush from "./sendNotification";

import "./styles.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class sendPushAdmin extends Component {
  state = {
    isLoading: false,
    login: "valid",
    popUpOpen: false,
    page: 0,
    search: "",
    data: [],
    pageCount: 0,
    id: null,
    selectedPackages: [],
    packagesAllList: [],
    popUpOpen: false,
    title: "",
    description: "",
    url: "/notifications",
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { page, search } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "pushall",
      search: search,
      qid: page * 25,
    };
    try {
      this.setState({ isLoading: true });

      const data = await axios.post(
        `${commonData["api"]}/admin/qbankdata`,
        body,
        { headers }
      );
      const packagesList = await axios.get(
        `${commonData["api"]}/adminmasterdata/packagelist`,
        {
          headers,
        }
      );

      console.log(data);
      this.setState({
        data: data.data[0],
        pageCount: data.data[1][0].count,
        isLoading: false,
        packagesAllList: packagesList.data[0],
      });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };
  renderPaginationButtons = (totalCount) => {
    const paidcount = Math.ceil(totalCount);
    const { page } = this.state;
    return (
      <div className="pagination">
        <Button
          className="btn navigate"
          onClick={() => {
            this.setState(
              (prev) => ({ page: prev.page - 1, isLoading: true }),
              () => this.getData()
            );
          }}
          disabled={page === 0}
        >
          Back
        </Button>
        <Button
          className="btn navigate"
          onClick={() => {
            this.setState(
              (prev) => ({ page: prev.page + 1, isLoading: true }),
              () => this.getData()
            );
          }}
          disabled={page === paidcount - 1}
        >
          Next
        </Button>
      </div>
    );
  };
  dataTable = () => {
    const { data, pageCount } = this.state;
    // console.log(CouponData);
    const style = `table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width:100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 10px;
        height: "100%";
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }`;
    return (
      <div className="paiduserdiv">
        <style>{style}</style>
        <div className="adminTableButtons">
          <h3>All Push Notifcations</h3>

          <div>
            <Button
              className="btn exportbtn"
              onClick={() => {
                this.setState((p) => ({
                  popUpOpen: !p.popUpOpen,
                }));
              }}
            >
              Send New Message
            </Button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              {/* <th style={{ textAlign: "center" }}>Send Again</th> */}
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((e, i) => {
                const { title, message } = JSON.parse(e.data);
                console.log(title, message);
                return (
                  <tr key={"pushnote" + e.id}>
                    <td>
                      <div style={{ display: "flex" }}>
                        <p>{title}</p>
                      </div>
                    </td>

                    <td style={{ textAlign: "center" }}>{message}</td>

                    <td style={{ textAlign: "center" }}>
                      {/* <i
                        className="fas fa-edit"
                        style={{ marginRight: 10, cursor: "pointer" }}
                        // onClick={() =>

                        // }
                      ></i> */}
                      <i
                        className="fas fa-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState(
                            {
                              id: e.id,
                            },
                            this.deleteData
                          )
                        }
                      ></i>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <td colSpan={4}>
                  <p style={{ color: "black", textAlign: "center" }}>
                    No Data is Available...
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.renderPaginationButtons(pageCount)}
      </div>
    );
  };
  handleOpen = () => {
    this.setState((p) => ({
      popUpOpen: !p.popUpOpen,
      description: "",
      title: "",
      url: "",
      selectedPackages: [],
    }));
  };
  onChangePackageData = (e) => {
    console.log(e);
    const { packagesAllList } = this.state;
    const allGids = packagesAllList.map((e) => e.gid).join(",");

    this.setState({
      selectedPackages:
        typeof value === "string" ? e.target.value.split(",") : e.target.value,
    });
  };
  storePush = async (msg) => {
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "storePush",
      search: JSON.stringify(msg),
      qid: "0",
    };

    try {
      const data = await axios.post(
        `${commonData["api"]}/admin/qbankdata`,
        body,
        { headers }
      );
      this.getData();
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
    }
  };
  getUserPhone = async (gids) => {
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "getPhonePush",
      search: gids,
      qid: "0",
    };

    try {
      const data = await axios.post(
        `${commonData["api"]}/admin/qbankdata`,
        body,
        { headers }
      );
      return data.data[0][0].nums;
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
    }
  };
  sendMsg = async () => {
    const { description, title, selectedPackages, url } = this.state;
    if (title === "") {
      NotificationManager.error(`Please enter message title`);
    } else if (description === "") {
      NotificationManager.error(`Please enter message description`);
    } else {
      const msg = {
        title: title,
        message: description,
        url: url,
        web_buttons: [
          {
            id: "like-button",
            text: "➤ Know More ",
            url: url,
          },
        ],
      };
      if (selectedPackages.length !== 0) {
        const nums = selectedPackages; // await this.getUserPhone(selectedPackages.join(","));
        // console.log(nums);

        if (nums.length !== null) {
          const filterDat = [];
          nums.split(",").forEach((element) => {
            filterDat.push(
              {
                field: "tag",
                key: String(element), // "number",
                relation: "=",
                value: String(element),
              },
              { operator: "OR" }
            );
          });
          msg["filters"] = filterDat;
        }
      }
      try {
        console.log(msg);
        const countSend = await sendpush(msg);
        NotificationManager.success(
          `Message sent to ${countSend} Students Successfully..`
        );
        this.storePush(msg);
        this.setState({
          popUpOpen: false,
          description: "",
          title: "",
          url: "",
          selectedPackages: [],
        });
      } catch {
        NotificationManager.error(`Something Went Wrong`);
      }
    }
  };
  deleteData = async () => {
    const { id, data } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "deletePush",
      search: "",
      qid: id,
    };
    if (window.confirm("Do you really want to remove this data ?")) {
      try {
        await axios.post(`${commonData["api"]}/admin/qbankdata`, body, {
          headers,
        });
        // console.log(CouponData);
        this.setState(
          {
            data: data.filter((e) => e.id != id),
          },
          NotificationManager.success(`Notification Deleted Succesfully...`)
        );
      } catch (err) {
        NotificationManager.error(`Something Went Wrong`);
      }
    }
  };
  renderPopUp = () => {
    const {
      popUpOpen,
      packagesAllList,
      selectedPackages,
      title,
      url,
      description,
    } = this.state;
    // const allGids = packagesAllList.map((e) => e.gid).join(",");
    // console.log(allGids);
    return (
      <Dialog
        open={popUpOpen}
        onClose={this.handleOpen}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="supportdailog ">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="popupdata">
              <p>Send New Message</p>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="dailogContent">
          <TextField
            required
            className="input-box register catgroy"
            label="Msg Title"
            variant="filled"
            value={title}
            onChange={(e) => this.setState({ title: e.target.value })}
            focused
          />
          <TextField
            required
            className="input-box register catgroy"
            label="Msg Description"
            variant="filled"
            value={description}
            onChange={(e) => this.setState({ description: e.target.value })}
            focused
          />

          <br />
          <TextField
            required
            className="input-box register catgroy"
            label="Link URL"
            variant="filled"
            value={url}
            onChange={(e) => this.setState({ url: e.target.value })}
            focused
          />
          <br />

          {/* <Select
            required
            id="Please-Select-District"
            className="input-box dis"
            // label="Please Select Package"
            style={{ width: "96%" }}
            value={selectedPackages}
            name="selectedPackages"
            multiple
            displayEmpty
            onChange={this.onChangePackageData}
            renderValue={(selectedPackagesList) => {
              if (selectedPackagesList.length === 0) {
                return <em>Please Select Package</em>;
              }

              return selectedPackagesList.join(",");
            }}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="" className="attempt-option-select">
              Please Select Users from Package
            </MenuItem>
            {/* <MenuItem className="selectionbox" value={allGids}>
              <Checkbox
                indeterminate={
                  selectedPackages.length > 0 &&
                  selectedPackages.length < packagesAllList.length
                }
                checked={
                  selectedPackages.length > 0 &&
                  selectedPackages.length === packagesAllList.length
                }
              />
              <ListItemText primary={"Select All"} />
            </MenuItem> 
            {packagesAllList.map((e, i) => (
              <MenuItem
                className="selectionbox"
                value={e.gid}
                key={"multiselect" + i}
              >
                <Checkbox checked={selectedPackages.indexOf(e.gid) > -1} />
                <ListItemText primary={e.group_name} />
              </MenuItem>
            ))}
          </Select> */}
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "center", marginLeft: -10 }}
        >
          <Button
            className="btn header-btns attemptbtn attempt-btns submit popbtn"
            onClick={this.handleOpen}
          >
            Cancel
          </Button>
          <Button
            className="btn header-btns attemptbtn attempt-btns popbtn"
            onClick={this.sendMsg}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  render() {
    const { isLoading, login, popUpOpen } = this.state;
    return (
      <>
        {!isLoading && login === "valid" && (
          <>
            <div className="desktopsidebar">
              <div className="desktopsidebarmenuexamdetailsAdmin">
                <AdminMenu />
              </div>
              <Header />

              <Divider color="white" />
              <div className="viewresultsdesktop admin">
                {this.dataTable()}
                {popUpOpen && this.renderPopUp()}
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="loader-main-container">
            <Loader />
          </div>
        )}
        {!isLoading && login === "invalid" && (
          <div className="not-found-div">
            <img
              src={invalid}
              className="not-found-img"
              alt="not-found-image"
            />
            <Link to="/" className="linkto">
              <Button
                variant="contained"
                className="btn"
                style={{ marginTop: 20 }}
              >
                Go to HomePage
              </Button>
            </Link>
          </div>
        )}
        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

export default sendPushAdmin;
