import react from "react";
import Header from "../Header";
import Loader from "../Loader";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Cookie from "js-cookie";
import Button from "@mui/material/Button";
import AdminMenu from "./AdminMenu";
import { Link } from "react-router-dom";

import invalid from "../invalid.png";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./styles.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class BookOrder extends react.Component {
  state = {
    isLoading: false,
    login: "valid",
    dataLink: [],
    page: 0,
    popUpOpen: false,
    couponId: null,
    editorLoading: false,
    search: "",
    dataCount: 0,
    linkName: "",
    popupType: "",
    link: "",
    searchClicked: false,
    category: "",
    allMessages: [],
  };

  componentDidMount() {
    this.setState({ isLoading: true }, this.getData);
  }
  getData = async () => {
    try {
      const response = await axios.get(
        "https://books.navachaitanya.net/api/order?key=ncexmasnookeshh"
      );
      // console.log("🚀 ~ viewMessages ~ response:", response);
      this.setState({ allMessages: response.data });
    } catch (error) {
      console.error("Error fetching package list:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, login, allMessages } = this.state;
    return (
      <>
        {!isLoading && login === "valid" && (
          <>
            <div className="desktopsidebar">
              <div className="desktopsidebarmenuexamdetailsAdmin">
                <AdminMenu />
              </div>
              <Header />

              <Divider color="white" />
              <div className="viewresultsdesktop admin">
                <>
                  <h3 style={{ color: "white" }}>All Orders(Paid)</h3>
                  <div>
                    {allMessages.length > 0 &&
                      allMessages.filter((e) => e.paymentStatus === "Success")
                        .length > 0 && (
                        <>
                          <style>
                            {`
        /* Add some styling to the table */
        table {
          width: 100%;
          border-collapse: collapse;
          
        }

        /* Style the table headers */
        th {
          background-color: #f2f2f2;
          padding: 8px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }

        /* Style the table cells */
        td {
          padding: 8px;
          border: 1px solid #ddd;
          background-color: #f2f2f2;
        }

        /* Add some hover effect to the table rows */
        tr:hover {
          background-color: #f5f5f5;
        }
      `}
                          </style>
                          <table>
                            <thead>
                              <tr>
                                {/* Dynamically create headers based on object properties */}
                                {Object.keys(allMessages[0]).map((header) => (
                                  <th key={header}>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {allMessages.map((item) => (
                                <tr key={item.msgId}>
                                  {/* Render each object property as a table cell */}
                                  {Object.values(item).map((value, index) => (
                                    <td key={index}>{value}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                  </div>

                  <h3 style={{ color: "white", marginTop: 10 }}>
                    All Orders(Pending)
                  </h3>
                  <div>
                    {allMessages.length > 0 &&
                      allMessages.filter((e) => e.paymentStatus !== "Success")
                        .length > 0 && (
                        <>
                          <style>
                            {`
        /* Add some styling to the table */
        table {
          width: 100%;
          border-collapse: collapse;
     
        }

        /* Style the table headers */
        th {
          background-color: #f2f2f2;
          padding: 8px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }

        /* Style the table cells */
        td {
          padding: 8px;
          border: 1px solid #ddd;
          background-color: #f2f2f2;
        }

        /* Add some hover effect to the table rows */
        tr:hover {
          background-color: #f5f5f5;
        }
      `}
                          </style>
                          <table>
                            <thead>
                              <tr>
                                {/* Dynamically create headers based on object properties */}
                                {Object.keys(allMessages[0]).map((header) => (
                                  <th key={header}>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {allMessages.map((item) => (
                                <tr key={item.msgId}>
                                  {/* Render each object property as a table cell */}
                                  {Object.values(item).map((value, index) => (
                                    <td key={index}>{value}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                  </div>
                </>
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="loader-main-container">
            <Loader />
          </div>
        )}
        {!isLoading && login === "invalid" && (
          <div className="not-found-div">
            <img
              src={invalid}
              className="not-found-img"
              alt="not-found-image"
            />
            <Link to="/" className="linkto">
              <Button
                variant="contained"
                className="btn"
                style={{ marginTop: 20 }}
              >
                Go to HomePage
              </Button>
            </Link>
          </div>
        )}
        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

export default BookOrder;
