import { Oval } from "react-loader-spinner";
import loaderback from "./loaderback.png";

const Loader = () => {
  return (
    <div className="loader-spinner">
      <Oval
        height={100}
        width={100}
        color="orange"
        ariaLabel="loading"
        secondaryColor="white"
        strokeWidth={3}
      />
      <img
        src={loaderback}
        className="loader-img"
        alt="nava-chaitanya-loading-image"
      />
    </div>
  );
};

export default Loader;
