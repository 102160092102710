// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SubjectSelection.css */

.subject-selection-container {
     width:100vw;
    margin-top: 5%;
    /* height: 50vh; */
    /* padding: 20px; */
    background-color: #e7e1e1;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* margin-left: 2px;
    margin-right: 2px; */
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .subjects-grid {
    display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   
  }
  
  .subject-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .subject-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subject-item.selected {
    background-color: #4caf50;
    color: white;
  }
 .submibtn{
    margin-top: 50px !important;
    margin-bottom: 10px !important;
  }  `, "",{"version":3,"sources":["webpack://./src/components/SubjectSelection.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;KACK,WAAW;IACZ,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;IACvC,kBAAkB;IAClB;wBACoB;EACtB;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;GACd,mBAAmB;GACnB,uBAAuB;GACvB,eAAe;;EAEhB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,2BAA2B;IAC3B,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;CACD;IACG,2BAA2B;IAC3B,8BAA8B;EAChC","sourcesContent":["/* SubjectSelection.css */\n\n.subject-selection-container {\n     width:100vw;\n    margin-top: 5%;\n    /* height: 50vh; */\n    /* padding: 20px; */\n    background-color: #e7e1e1;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    /* margin-left: 2px;\n    margin-right: 2px; */\n  }\n  \n  h2 {\n    margin-bottom: 20px;\n    font-size: 24px;\n    color: #333;\n  }\n  \n  .subjects-grid {\n    display: flex;\n   flex-direction: row;\n   justify-content: center;\n   flex-wrap: wrap;\n   \n  }\n  \n  .subject-item {\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    padding: 10px;\n    transition: all 0.3s ease;\n    cursor: pointer;\n    margin-top: 10px;\n    margin-right: 10px;\n  }\n  \n  .subject-item:hover {\n    transform: translateY(-3px);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .subject-item.selected {\n    background-color: #4caf50;\n    color: white;\n  }\n .submibtn{\n    margin-top: 50px !important;\n    margin-bottom: 10px !important;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
