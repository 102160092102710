// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Ramabhadra";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(85deg, #434343, #262626);
  /* border-radius: 15px; */
  /* box-shadow: -2rem 0 3rem -2rem #000; */
  /* height: 20vh; */
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (min-width: 768px) {
  /* body {
    padding-left: 11% !important;
    padding-right: 11% !important;
  } */
}
.MuiFormLabel-root.Mui-focused {
  color: green !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,yBAAyB;EACzB,mCAAmC;EACnC,kCAAkC;EAClC,oDAAoD;EACpD,yBAAyB;EACzB,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE;aACW;AACb;AACA;EACE;;;KAGG;AACL;AACA;EACE,uBAAuB;AACzB","sourcesContent":["@import url(\"https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css\");\nbody {\n  margin: 0;\n  font-family: \"Ramabhadra\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: linear-gradient(85deg, #434343, #262626);\n  /* border-radius: 15px; */\n  /* box-shadow: -2rem 0 3rem -2rem #000; */\n  /* height: 20vh; */\n  height: auto;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n@media (min-width: 768px) {\n  /* body {\n    padding-left: 11% !important;\n    padding-right: 11% !important;\n  } */\n}\n.MuiFormLabel-root.Mui-focused {\n  color: green !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
