import {
  Button,
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import AdminMenu from "./AdminMenu";
import Select from "@mui/material/Select";
import axios from "axios";
import commonData from "../../importanValue";
import Cookies from "js-cookie";
import { ExcelRenderer } from "react-excel-renderer";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};
const MessageSMSComponent = () => {
  const [message, setMessage] = useState("");
  const [error, setErr] = useState("");
  const [numbers, setNumbers] = useState("");
  const [message1, setMessage1] = useState(null);
  const [message2, setMessage2] = useState("");
  const [rows, setRows] = useState([]);
  const [allMessages, setMessages] = useState([]);
  const [templates, setAllTEmplates] = useState([]);
  const [allMessages2, setMessages2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSentMessages, setShowMessages] = useState(false);
  const [cols, setCols] = useState([]);
  const [packagesAllList, setPackages] = useState([]);
  const [selectedUser, setSelectedUsers] = useState([]);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);
  const [selectedValue, setSelectedValue] = useState("en");
  const [selectedTypeValue, setSelectedTypeValue] = useState("none");
  const [storefilehandlerkey, setkey] = useState("");
  const location = useLocation();

  const handleTypeChange = (event) => {
    setSelectedTypeValue(event.target.value);
    setMessage1(
      event.target.value === "none" ? null : JSON.parse(event.target.value)
    );
  };
  // Function to handle changes in the dropdown selection
  const handleSelectionChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleMessageChange1 = (event) => {
    setMessage1(event.target.value);
  };
  const handleMessageChange2 = (event) => {
    setMessage2(event.target.value);
  };
  const handleNumberChange2 = (event) => {
    setNumbers(event.target.value.trim());
  };

  useEffect(() => {
    const nextUrl = location.search.split("?type=")[1];
    const fetchData = async () => {
      try {
        const token = Cookies.get("jwt_token");
        const headers = {
          "Content-Type": "application/json",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        };

        const response = await axios.get(
          `${commonData.api}/adminmasterdata/packagelist`,
          {
            headers,
          }
        );

        // Assuming the API response contains an array of packages
        setPackages(response.data[0]);
      } catch (error) {
        console.error("Error fetching package list:", error);
      }
    };
    if (nextUrl === "show") {
      viewMessages();
      setShowMessages(true);
      return;
    }
    getCount();
    fetchData();
    getTEmplates();

    setShowMessages(false);
  }, []);

  const viewMessages = async () => {
    try {
      const response = await axios.get(
        "https://phpstack-702151-4218790.cloudwaysapps.com/get-messages"
      );
      // Assuming the API response contains an array of packages
      setMessages2(response.data[0]);
      setMessages(response.data[1]);
    } catch (error) {
      console.error("Error fetching package list:", error);
    }
  };
  const getTEmplates = async () => {
    try {
      const response = await axios.get(
        "https://examsapi.navachaitanya.net/sms-templates"
      );
      if (response.data?.result) {
        setAllTEmplates({
          Content_Template_Ids: response.data?.data.Content_Template_Ids.filter(
            (e) => e.Template_Status === "1"
          ),
        });
      } else {
        setAllTEmplates([]);
      }
    } catch (error) {
      console.error("Error fetching package list:", error);
    }
  };
  const getCount = async () => {
    try {
      const response = await axios.get(
        "https://examsapi.navachaitanya.net/sms-count"
      );
      if (response.data?.result) {
        console.log(
          "🚀 ~ getCount ~ response.data?.result:",
          response.data?.data
        );
        if (response.data?.data?.Recharge_Details.length > 0) {
          setMessage2(
            response.data?.data.Recharge_Details[0].SMS_Balance_Count
          );
        }
      } else {
        setMessage2("");
      }
    } catch (error) {
      console.error("Error fetching package list:", error);
    }
  };
  console.log("templates", templates);
  const handleSendClick = async () => {
    setIsLoading(true);
    setSelectedUsers([]);
    if (selectedPackageIds.length > 0) {
      const body3 = {
        type: "GETALLUsersBasedOnGid",
        search: selectedPackageIds.join(","),
        qid: 0,
      };
      const token = Cookies.get("jwt_token");
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
        "Access-Control-Allow-Origin": "*",
      };

      const GETALLCOUNT = await axios.post(
        `${commonData["api"]}/admin/qbankdata`,
        body3,
        {
          headers,
        }
      );
      if (GETALLCOUNT) {
        const allUser = GETALLCOUNT.data[0];
        setSelectedUsers(allUser);
      }
    } else if (rows.length > 0) {
      const allUsers = [];
      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        const val = element[cols.indexOf("contact_no")];
        if (val && String(val).trim() !== "") {
          allUsers.push({ contact_no: String(val).trim() });
        }
      }
      setSelectedUsers(allUsers.filter((e) => e && String(e).trim() !== ""));
    } else if (String(numbers).trim().length > 0) {
      const allUsersInfo = [];
      let error = "";
      numbers.split(",").forEach((e) => {
        if (e && String(e).trim().length > 0 && e.trim().length === 10) {
          allUsersInfo.push({
            contact_no: e,
          });
        } else {
          error = e + "," + error;
        }
      });
      if (error) {
        setErr("Error in this numbers or check extra commas >> " + error);
        NotificationManager.error("Rectify the issue to move forward.");
        setIsLoading(false);
        setSelectedUsers([]);
        return;
      }
      setErr("");
      setSelectedUsers(allUsersInfo);
    } else {
      //   if (!message || !message1 || !message2)
      //     NotificationManager.error("Please enter the message.");
      if (
        selectedPackageIds.length === 0 &&
        selectedUser.length === 0 &&
        rows.length === 0 &&
        numbers.trim().length === 0
      ) {
        NotificationManager.error(
          "Please select any group/Import through Excel/Add Numbers"
        );
      }

      //   if (selectedUser.length === 0)
      //     NotificationManager.error("Please select atleast one user");
    }
    setIsLoading(false);
    // Add additional logic for sending the message
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const sendMessage = async () => {
    try {
      if (
        selectedTypeValue !== "none" &&
        message &&
        // message2 &&
        (selectedPackageIds.length > 0 ||
          selectedUser.length > 0 ||
          numbers.trim().length > 0)
      ) {
        // console.log(message1);
        const data = await axios.post(`${commonData["api"]}/send-bulk-sms`, {
          phone: selectedUser,
          message,
          templateId: message1?.Template_Id,
          header: message1?.Header,
          Entity_Id: message1?.Entity_Id,
          Message_Type: message1?.Message_Type,
          Is_Unicode: message1?.Is_Unicode,
          Template_Keys:
            message1?.Template_Keys.length > 0
              ? message1?.Template_Keys[0]
              : null,
        });
        if (data?.data) {
          NotificationManager.success("Messages sent.");
        } else {
          NotificationManager.success("Something went wrong.");
        }
      } else {
        if (!selectedTypeValue !== "none")
          NotificationManager.error("Please select template.");
        if (!message) NotificationManager.error("Please enter the message.");
        if (
          selectedPackageIds.length === 0 &&
          selectedUser.length === 0 &&
          rows.length === 0 &&
          numbers.trim().length === 0
        )
          NotificationManager.error(
            "Please select any group/Import through Excel/add phone numbers"
          );

        if (selectedUser.length === 0)
          NotificationManager.error("Please select atleast one user");
      }
    } catch (error) {
      NotificationManager.error("Something Went Wrong");
    }
    // Add additional logic for sending the message
  };

  const onChangePackageData = (e) => {
    setSelectedPackageIds(
      typeof value === "string" ? e.target.value.split(",") : e.target.value
    );
  };

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    // console.log(fileObj.name.split(".xlsx"));
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        NotificationManager.error("Please upload proper file");
        console.log(err);
      } else {
        setCols(resp.rows.slice(0, 1)[0]);
        setRows(resp.rows.slice(1, resp.rows.length));
        NotificationManager.success("File Added");
      }
    });
  };

  return (
    <div className="desktopsidebar">
      <div className="desktopsidebarmenuexamdetailsAdmin">
        <AdminMenu />
        <div>
          <NotificationContainer />
        </div>
      </div>
      <Header />

      <Divider color="white" />

      <div className="viewresultsdesktop admin">
        <div style={styles.container}>
          {!showSentMessages && (
            <>
              <h3>
                Send SMS to Users{" "}
                {message2 ? "( Remaining SMS Balance : " + message2 + " )" : ""}
              </h3>
              <div style={styles.container}>
                <div style={{ display: "flex" }}>
                  <label
                    htmlFor="dropdown2"
                    style={{
                      fontSize: "16px",
                      marginBottom: "8px",
                      display: "block",
                    }}
                  >
                    Select Template:
                  </label>
                  <select
                    id="dropdown2"
                    value={selectedTypeValue}
                    onChange={handleTypeChange}
                    style={{
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      width: "200px",
                      marginBottom: "16px",
                    }}
                  >
                    <option value="none">Select Template</option>
                    {templates?.Content_Template_Ids?.map((e) => (
                      <option value={JSON.stringify(e)}>
                        {e?.Template_Name}
                      </option>
                    ))}
                  </select>
                </div>
                <label style={styles.label}>
                  Message :<br />
                  {message1 && selectedTypeValue !== "none" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message1?.Template_Message_DLT?.replace(
                          /\n/g,
                          "<br/>"
                        ),
                      }}
                    ></div>
                  )}
                </label>
                <br />
                <label style={styles.label}>
                  Message variable :{" "}
                  <input
                    type="text"
                    value={message}
                    onChange={handleMessageChange}
                    style={styles.input}
                  />
                </label>
                <br />
                <div
                  style={{
                    marginTop: 10,
                    border: "1px solid green",
                    padding: 5,
                    margin: 10,
                  }}
                >
                  <label style={styles.label}>Get users from Package or</label>
                  <div>
                    <Select
                      required
                      id="Please-Select-District"
                      className="input-box dis"
                      // label="Please Select Package"
                      style={{ width: 500 }}
                      value={selectedPackageIds}
                      name="selectedPackages"
                      multiple
                      displayEmpty
                      onChange={onChangePackageData}
                      renderValue={(selectedPackages) => {
                        if (selectedPackages.length === 0) {
                          return <em>Please Select Package</em>;
                        }

                        return selectedPackages.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        disabled
                        value=""
                        className="attempt-option-select"
                      >
                        Please Select Package
                      </MenuItem>
                      <MenuItem
                        className="selectionbox"
                        value={"All Users"}
                        key={"multiselect All Users"}
                      >
                        <Checkbox
                          checked={selectedPackageIds.indexOf("All Users") > -1}
                        />
                        <ListItemText primary={"All Users"} />
                      </MenuItem>
                      {packagesAllList
                        .filter((e) => e?.price > 0)
                        .map((e, i) => (
                          <MenuItem
                            className="selectionbox"
                            value={e.gid}
                            key={"multiselect" + i}
                          >
                            <Checkbox
                              checked={selectedPackageIds.indexOf(e.gid) > -1}
                            />
                            <ListItemText primary={e.group_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <label style={styles.label}>Import from Excel</label>
                    <div>
                      <input
                        type="file"
                        onChange={fileHandler}
                        style={{ padding: "10px" }}
                      />
                    </div>
                  </div>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1DD5USXWri1A6T0qfdMYICUQdxYTIpCKaNAooNXoatD0/edit?usp=sharing"
                    target="_blank"
                  >
                    Demo Excel file
                  </a>
                  <br />
                  <br />
                  <p>
                    Or add Users numbers in comma separated
                    <br /> example: 7396019228,8464011520
                  </p>
                  <div style={{ width: "80%" }}>
                    <label style={styles.label}>
                      Add phoneNumbers
                      <textarea
                        type="text"
                        value={numbers}
                        onChange={handleNumberChange2}
                        style={styles.input}
                      />
                    </label>
                  </div>
                  <p style={{ color: "red" }}>{error}</p>
                  <div style={{ marginTop: 20 }}>
                    <button onClick={handleSendClick} style={styles.button}>
                      Get Users
                    </button>
                    <span style={{ marginLeft: 10 }}>
                      {isLoading
                        ? "Fetching the Users Info"
                        : `(${selectedUser.length}) Users are available`}
                    </span>
                  </div>
                </div>

                {/* <div>Selected Users ({selectedUser.length})</div> */}
                {selectedUser.length > 0 && (
                  <div>
                    {" "}
                    <Button
                      variant="contained"
                      className="btn"
                      style={{ marginTop: 40 }}
                      onClick={sendMessage}
                    >
                      Send Message to ({selectedUser.length}) Users
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {showSentMessages && (
            <>
              <h3>All sent Messages </h3>
              <div style={styles.container}>
                {allMessages.length > 0 && (
                  <>
                    <style>
                      {" "}
                      {`/* Add some styling to the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Style the table headers */
th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Style the table cells */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Add some hover effect to the table rows */
tr:hover {
  background-color: #f5f5f5;
}
`}
                    </style>
                    <table>
                      <thead>
                        <tr>
                          {/* Dynamically create headers based on object properties */}
                          {Object.keys(allMessages2[0]).map((header) => (
                            <th key={header}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allMessages2.map((item) => (
                          <tr key={item.msgId}>
                            {/* Render each object property as a table cell */}
                            {Object.values(item).map((value) => (
                              <td key={value}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
                {allMessages.length > 0 && (
                  <>
                    <style>
                      {" "}
                      {`/* Add some styling to the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Style the table headers */
th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Style the table cells */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Add some hover effect to the table rows */
tr:hover {
  background-color: #f5f5f5;
}
`}
                    </style>
                    <table>
                      <thead>
                        <tr>
                          {/* Dynamically create headers based on object properties */}
                          {Object.keys(allMessages[0]).map((header) => (
                            <th key={header}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allMessages.map((item) => (
                          <tr key={item.msgId}>
                            {/* Render each object property as a table cell */}
                            {Object.values(item).map((value) => (
                              <td key={value}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    // maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  select: {
    width: "100%",
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    height: "100px", // Adjust the height as needed
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px 15px",
    fontSize: "16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default MessageSMSComponent;
