const commonData = {
  "2factorAPI": "a871f3f8-2733-11ec-a13b-0200cd936042", //live
  smstemp: "exams", //live
  // "2factorAPI": "3d34c42c-8686-11ec-b9b5-0200cd936042",
  // smstemp: "",

  api: "https://examsapi.navachaitanya.net", // ap
  apapi: "https://examsapi.navachaitanya.net", // ap
  //  api: "https://phpstack-702151-2512759.cloudwaysapps.com", // exams2
  // api: "https://phpstack-702151-2389596.cloudwaysapps.com", // testing

  // api: "http://localhost:4002",
  app: "https://exams.navachaitanya.net",
  payukey: "aYEdQEUZ",
  paysalt: "d2aOrQ4FGU",
  tinymice: "nvxe3e4bc3g47rvgm716zyb4h6w8mgd42rs4p8zptbbm84fi",
  // push
  // app_id: "466146a1-7165-41af-bca8-fa9ed63c0552", //nc exmas
  // auth_key: "OTllYWM4ZmItNWY4Ny00ZDRiLWFjM2YtMGZjMDc0ZDg2ZTBk", //nc exmas
  // app_id: "0086b69c-959f-49c5-9cd6-2a813a27fb00", // local
  // auth_key: "MGQ5MmJhNTktOTMyZC00OGEwLTlmMmQtZmFkMGExODhlOWY1", // local
  // app_id: "04ca8db0-475a-477c-9d13-8106bc4d2154", //ts exmas
  // auth_key: "ZDQzOTdhNDQtY2ZmNy00ZDYxLWFjZTQtNzZhMWMxZDA0Zjcy", //ts exmas
  app_id: "4f2fd23f-377c-40be-8cca-7867d0e6fdd6", //ap exmas
  msgApi: "https://phpstack-702151-4218790.cloudwaysapps.com/send-message", //ap exmas
  auth_key: "Mzc5NjY0ZjgtNjUxMC00YjNmLTk1MWEtZGUxMmUwNDMwNTJj", //ap exmas
  userMenu: [
    {
      url: "/",
      name: "Home",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/results-list",
      name: "Result",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/admin/qbank/reported-questions/0/0",
      name: "Reported Questions",
      enabled: localStorage.getItem("user") === "2",
    },
    {
      url: "/exams-schedules",
      name: "Exam Schedule",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/syllabus-in-telugu",
      name: "Syllabus in Telugu",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/study-materials",
      name: "Study Materials",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/user-profile",
      name: "My Profile",
      openInNewTab: false,
      enabled: true,
    },
    {
      url: "/notifications",
      name: "Notifications",
      openInNewTab: false,
      enabled: true,
    },
  ],
  AdminMenu: [
    {
      url: "/support",
      name: "Support",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/dashboard",
      name: "Dashboard",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    // {
    //   url: "https://books.navachaitanya.net/orders",
    //   name: "View Book Orders List",
    //   openInNewTab: true,
    //   enabled: localStorage.getItem("user") === "1",
    // },
    {
      url: "/send-sms-messages",
      name: "Send SMS to students",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/send-messages",
      name: "Send Whatsapp Messages",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/send-messages?type=show",
      name: "View Whatsapp Messages",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/users",
      name: "Users",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/qbank/view-all",
      name: "QBank",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/exams/view-all",
      name: "Exams List",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/exams/view-all?type=pdfs",
      name: "PDF List",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/exams/view-all?type=videos",
      name: "Videos List",
      openInNewTab: true,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/group/add-new-package",
      name: "Packages",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/qbank/reported-questions/0/0",
      name: "Reported Questions",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/result/view-all",
      name: "Result List",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/qbank/categories",
      name: "Categories List",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/others/coupons",
      name: "Discount Coupons",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/others/send-push",
      name: "Send Push",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/others/auto-responder",
      name: "Auto Responder",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
    {
      url: "/admin/user/referdata",
      name: "Refer Data",
      openInNewTab: false,
      enabled: localStorage.getItem("user") === "1",
    },
  ],
};

export default commonData;
