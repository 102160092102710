import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { withRouter } from "react-router";
import "./styles.css";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Divider from "@mui/material/Divider";
import commonData from "../../importanValue";
// import { loadComponent } from "../../App";
class AdminMenu extends React.Component {
  state = { clickType: "Home", openLinks: false };
  logoutUser = () => {
    const { history } = this.props;
    Cookie.remove("jwt_token");
    // console.log(history);
    localStorage.removeItem("num");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("uid");
    history.replace("/login");
  };
  render() {
    // console.log(this.props);
    const { clickType, openLinks } = this.state;
    const token = Cookie.get("jwt_token");
    return (
      <div>
        <div>
          <h1 className="heading-title desktoptitle">నవచైతన్య కాంపిటీషన్స్</h1>
          <p className="heading-para desktoppara">
            చింతలపూడి పశ్చిమ గోదావరి జిల్లా
          </p>
          <Divider color="white" style={{ marginTop: 15 }} />
          <a
            href="https://books.navachaitanya.net/orders"
            target="_blank"
            rel="noreferrer"
            className="linkto"
            
          >
            <div className="deskmenustyle" style={{ marginTop: 15 }}>
              <div className="deskmenuIcon">➤</div>
              <p className="deskmenuPara">View Book Orders List</p>
            </div>
          </a>

          {commonData.userMenu.concat(commonData.AdminMenu).map((eachMen) => {
            return eachMen.enabled ? (
              <Link
                to={eachMen.url}
                className="linkto"
                target={eachMen.openInNewTab ? "_blank" : ""}
              >
                <div className="deskmenustyle" style={{ marginTop: 15 }}>
                  <div className="deskmenuIcon">➤</div>
                  <p className="deskmenuPara">{eachMen.name}</p>
                </div>
              </Link>
            ) : null;
          })}
          <div
            className="deskmenustyle"
            onClick={() =>
              this.setState((p) => ({
                clickType: "Common Links",
                openLinks: !p.openLinks,
              }))
            }
            style={{ cursor: "pointer" }}
          >
            <div className="deskmenuIcon">
              <GroupIcon />
            </div>
            <p className="deskmenuPara">Common Links</p>
          </div>

          {token !== undefined && (
            <>
              {clickType === "Common Links" && openLinks && (
                <div className="innerMenuclik">
                  {/* <Link
                    to="/admin/others/all-links/Pdf"
                    className="linkto"
                    target={"_blank"}
                  >
                    <div className="deskmenustyle">
                      <div className="deskmenuIcon innermenuclr">➤</div>
                      <p className="deskmenuPara">PDF Links</p>
                    </div>
                  </Link> */}
                  <hr />
                  <Link
                    to="/admin/others/all-links/Syllabus"
                    className="linkto"
                    target={"_blank"}
                  >
                    <div className="deskmenustyle">
                      <div className="deskmenuIcon innermenuclr">➤</div>
                      <p className="deskmenuPara">Syllabus</p>
                    </div>
                  </Link>
                  <hr />
                  <Link
                    to="/admin/others/all-links/Schedule"
                    className="linkto"
                    target={"_blank"}
                  >
                    <div className="deskmenustyle">
                      <div className="deskmenuIcon innermenuclr">➤</div>
                      <p className="deskmenuPara">Schedule</p>
                    </div>
                  </Link>
                  <hr />
                  <Link
                    to="/admin/others/all-links/CA"
                    className="linkto"
                    target={"_blank"}
                  >
                    <div className="deskmenustyle">
                      <div className="deskmenuIcon innermenuclr">➤</div>
                      <p className="deskmenuPara">Study Materials</p>
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AdminMenu);
